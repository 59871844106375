.bg-blue {
  background-color: var(--main-color) !important;
  color: #fff !important;
}

.bg-green {
  background-color: #04a807;
}

.text-green {
  color: #04a807;
}

.text-red {
  color: #ed260b;
}

.fixed-height-200 {
  height: 200px;
  overflow-y: scroll;
}

.fixed-height-200 p {
  margin-bottom: 0;
}

.cursor {
  cursor: pointer;
}

.red {
  color: red;
}

/* Quick Search */
.quick-search button {
  border: 1px solid #e5e6e7;
  padding: 0 10px;
  background-color: #fff;
}

/* Advanced Search */
.internal-title-bar {
  border-bottom: 2px solid var(--secondary-color);
  color: var(--secondary-color);
}

.internal-title-bar h3 {
  font-weight: 600;
}

/* Dashboard */
.table-responsive.table-fixed-height {
  height: 350px;
  overflow-y: scroll;
}

.fixed-height {
  height: 350px;
  overflow-y: scroll;
}

/* Contact Overview */
.contact-overview {
  border: 2px solid red;
}

.contact-overview label {
  color: var(--main-color);
  font-size: 0.7rem;
  font-weight: bold;
  margin-bottom: 0;
}

/* Tasks and other table background */
.row-bg-red td {
  background-color: #f19e9c;
  color: #333;
  padding-left: 4px;
}

.row-bg-amber td {
  background-color: #daa568;
  color: #333;
  padding-left: 4px;
}

.row-bg-green td {
  background-color: #7bcb7c;
  color: #333;
  padding-left: 4px;
}

.row-bg-light-green td {
  background-color: #d0ffd1;
  color: #333;
  padding-left: 4px;
}

/* Navigation links on the dashboard */
.dashboard-nav {
  padding: 0px;
  margin-bottom: 20px;
}

.dashboard-nav ul {
  width: 100%;
  background-color: var(--main-color);
}

/* Under offer banner */
.banner {
  position: absolute;
  z-index: 9;
  padding: 3px 20px;
  color: #fff;
  background: var(--secondary-color);
  margin: 1px auto auto 1px;
}

.search-string {
  background-color: #fffa69;
}

.ucWord, 
.search-string-name {
  text-transform: capitalize;
}

.swal2-container {
  z-index: 9999!important;
}
